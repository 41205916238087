<template>
  <div class="rule-history-drawer">
    <el-drawer
      title="触发记录"
      :visible.sync="show"
      :size="800"
      :before-close="
        () => {
          $emit('close');
        }
      "
    >
      <div class="filter-line">
        <!--        <span class="filter-label">触发时间：</span>-->
        <!--        <el-date-picker-->
        <!--          v-model="timeRange"-->
        <!--          type="datetimerange"-->
        <!--          value-format="timestamp"-->
        <!--          range-separator="至"-->
        <!--          start-placeholder="开始日期"-->
        <!--          end-placeholder="结束日期"-->
        <!--          size="mini"-->
        <!--          @change="timeRangeChange"-->
        <!--        >-->
        <!--        </el-date-picker>-->

        <span class="query-label"> 触发时间： </span>
        <el-date-picker
          style="width: 200px"
          v-model="listQuery.startTime"
          value-format="timestamp"
          type="datetime"
          placeholder="选择开始时间"
        >
        </el-date-picker>
        <span class="date-range-span"> - </span>
        <el-date-picker
          style="width: 200px; margin-right: 20px"
          value-format="timestamp"
          v-model="listQuery.endTime"
          type="datetime"
          placeholder="选择结束时间"
        >
        </el-date-picker>
        <el-button type="primary" size="small" @click="timeRangeChange"
          >查询</el-button
        >
      </div>
      <el-table
        v-loading="pageLoading"
        :data="tableData"
        style="width: 100%; padding: 0 15px"
      >
        <el-table-column
          type="expand"
          v-if="dataset.ruleType !== 'schedule'"
          width="120"
          label="查看数据源"
        >
          <template slot-scope="props">
            <pre style="padding: 0 30px; line-height: 20px">
              {{ formatJsonForNotes(props.row.originalData) || "" }}
            </pre>
          </template>
        </el-table-column>
        <el-table-column label="触发时间" prop="creationDate">
          <template slot-scope="props">
            <span>
              {{ props.row.creationDate | dateFormat("YYYY-MM-DD HH:mm:ss") }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="规则名称" prop="ruleName"> </el-table-column>
      </el-table>
      <Pagination
        style="margin: 20px 10px"
        layout="sizes,total , prev, pager, next, jumper"
        :background="false"
        :total="total"
        :page.sync="listQuery.current"
        :limit.sync="listQuery.rowCount"
        @pagination="paginationChange"
      />
    </el-drawer>
  </div>
</template>

<script>
import { getRuleTriggerHistory } from "@/api/ruge/vlink/rule/rule";
import Pagination from "@/components/Pagination";
import { dateFormat } from "@/filters/index.js";
export default {
  name: "ruleHistoryDrawer",
  components: {
    Pagination,
  },
  props: {
    show: {
      type: Boolean,
      default() {
        return false;
      },
    },
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      pageLoading: false,
      tableData: [],
      listQuery: {
        current: 1,
        rowCount: 10,
        ruleKey: this.dataset.ruleKey,
        ruleVersion: this.dataset.ruleVersion,
        startTime: new Date().getTime() - 1000 * 60 * 60 * 24 * 7,
        endTime: null,
      },
      timeRange: [],
      total: 0,
    };
  },
  created() {
    this.getTableList();
  },
  methods: {
    timeRangeChange() {
      // this.listQuery.startTime = this.timeRange ? this.timeRange[0] : null;
      // this.listQuery.endTime = this.timeRange ? this.timeRange[1] : null;
      this.listQuery.current = 1;
      this.getTableList();
    },
    paginationChange(pageDatas) {
      const { page, limit } = pageDatas;
      this.listQuery.current = page;
      this.listQuery.rowCount = limit;
      this.getTableList();
    },
    getTableList() {
      this.pageLoading = true;
      getRuleTriggerHistory(this.listQuery)
        .then((res) => {
          res.rows.forEach((item) => {
            item.ruleName = this.dataset.ruleName || item.ruleName || "-";
            item.creationDate = dateFormat(
              item.creationDate,
              "YYYY-MM-DD HH:mm:ss"
            );
          });
          this.tableData = res.rows;
          this.total = res.total;
        })
        .finally(() => {
          this.pageLoading = false;
        });
    },
    formatJsonForNotes(json, options) {
      var reg = null,
        formatted = "",
        pad = 0,
        PADDING = "  "; // （缩进）可以使用'\t'或不同数量的空格
      // 可选设置
      options = options || {};
      // 在 '{' or '[' follows ':'位置移除新行
      options.newlineAfterColonIfBeforeBraceOrBracket =
        options.newlineAfterColonIfBeforeBraceOrBracket === true ? true : false;
      // 在冒号后面加空格
      options.spaceAfterColon =
        options.spaceAfterColon === false ? false : true;
      // 开始格式化...
      if (typeof json !== "string") {
        // 确保为JSON字符串
        json = JSON.stringify(json);
      } else {
        //已经是一个字符串，所以解析和重新字符串化以删除额外的空白
        json = JSON.parse(json);
        json = JSON.stringify(json);
      }
      // 在花括号前后添加换行
      reg = /([\{\}])/g;
      json = json.replace(reg, "\r\n$1\r\n");
      // 在方括号前后添加新行
      reg = /([\[\]])/g;
      json = json.replace(reg, "\r\n$1\r\n");
      // 在逗号后添加新行
      reg = /(\,)/g;
      json = json.replace(reg, "$1\r\n");
      // 删除多个换行
      reg = /(\r\n\r\n)/g;
      json = json.replace(reg, "\r\n");
      // 删除逗号前的换行
      reg = /\r\n\,/g;
      json = json.replace(reg, ",");
      // 可选格式...
      if (!options.newlineAfterColonIfBeforeBraceOrBracket) {
        reg = /\:\r\n\{/g;
        json = json.replace(reg, ":{");
        reg = /\:\r\n\[/g;
        json = json.replace(reg, ":[");
      }
      if (options.spaceAfterColon) {
        reg = /\:/g;
        json = json.replace(reg, ": ");
      }
      $.each(json.split("\r\n"), function (index, node) {
        var i = 0,
          indent = 0,
          padding = "";
        if (node.match(/\{$/) || node.match(/\[$/)) {
          indent = 1;
        } else if (node.match(/\}/) || node.match(/\]/)) {
          if (pad !== 0) {
            pad -= 1;
          }
        } else {
          indent = 0;
        }
        for (i = 0; i < pad; i++) {
          padding += PADDING;
        }
        formatted += padding + node + "\r\n";
        pad += indent;
      });
      return formatted;
    },
  },
};
</script>

<style lang="less" scoped>
.rule-history-drawer {
  .filter-line {
    padding: 0 20px;
    margin: 15px 0;
    .filter-label {
      font-size: 14px;
    }
  }
}
.query-label {
  font-size: 14px;
  display: inline-block;
  width: 85px;
  text-align: right;
  margin-right: 8px;
}
</style>